<template>
  <div>
    <div class="container-fluid bg-light p-2 fixed-bottom">
      <div class="row">
        <div class="flex xs4 copyright align--start">
          Copyright 2022 DZ Assets, LLC |
          <a href="mailto:info@cryptonotifi.xyz">info@cryptonotifi.xyz</a>
        </div>
        <div class="flex xs1 offset--xs7 align-content--end">
          <a href="https://discord.gg/A7wQSH4f" target="_blank">
            <va-image
              contain
              style="max-height: 16px"
              src="/Discord-Logo-Black.svg"
            />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.copyright {
  font-size: x-small;
  font-style: italic;
}
</style>
